/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <form action="#">
              <div class="form-row">
              <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label
                  >
                  <input
                    v-model="vmGPathSkillFormData.name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdescription">
                    {{cvDescriptionLabel}}</label
                  >
                  <textarea
                    v-model="vmGPathSkillFormData.description"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationimage">
                    {{cvImageLabel}}</label
                  >
                  <input
                    v-model="vmGPathSkillFormData.image"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcolor_code">
                    {{cvColorCodeLabel}}</label
                  >
                  <input
                    v-model="vmGPathSkillFormData.color_code"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="gpath_skillAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathSkills } from "../../../FackApi/api/GPathSkill"
import ApiResponse from "../../../Utils/apiResponse"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "GPathSkillAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "gpath_skills_add",
      cvCardTitle: "Add GPaths Skill",
      cvCardSubHeader: "Add GPaths Skill ",
      cvSubmitBtn: "Add",
      cvNameLabel: "name",
      cvDescriptionLabel: "description",
      cvImageLabel: "image",
      cvColorCodeLabel: "color code",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "GPathSkill Add Response",
      vmGPathSkillFormData: Object.assign({}, this.initFormData()),
      vmGPathSkillDesc: null,
      vmGPathSkillType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "name": "",
        "description": "",
        "image": "",
        "color_code": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathSkillFormData) {
          if (!this.vmGPathSkillFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * gpath_skillAdd
     */
    async gpath_skillAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let gpathSkillAddResp = await GPathSkills.gpath_skillAdd(this, this.vmGPathSkillFormData)
        await ApiResponse.responseMessageDisplay(this, gpathSkillAddResp)
        if (gpathSkillAddResp && !gpathSkillAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathSkillAddModal", gpathSkillAddResp.resp_data)
        }
        this.vmGPathSkillFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at gpath_skillAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
