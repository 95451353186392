/*eslint-disable */
// import GPathSkillModel from '../../Model/GPathSkill'
import request from '../../Utils/curl'

let GPathSkills = {
  /**
   * gpath_skillList
   */
  async gpath_skillList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_skills_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_skillList() and Exception:',err.message)
    }
  },

  /**
   * gpath_skillView
   */
  async gpath_skillView (context, gpathSkillId) {
    try {      
      let post_data = new FormData();
      post_data.append('gps_id', gpathSkillId);
      return await request.curl(context, "gpath_skills_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_skillView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_skillAdd
   */
  async gpath_skillAdd (context, gpath_skillObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_skillObj) {
        post_data.append(key, gpath_skillObj[key]);
      }

      return await request.curl(context, "gpath_skills_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_skillAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_skillEdit
   */
  async gpath_skillEdit (context, gpath_skillObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_skillObj) {
        post_data.append(key, gpath_skillObj[key]);
      }

      return await request.curl(context, "gpath_skills_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_skillEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_skillDelete
   */
  async gpath_skillDelete (context, gpathSkillId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gps_id', gpathSkillId);

      return await request.curl(context, "gpath_skills_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_skillDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathSkills
}
