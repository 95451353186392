<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title mt-4">
              <i class="fa-solid fa-brain" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" class="mt-4" @click="gpath_skillAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_skillObjList && gpath_skillObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_skillObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >

                  <template v-slot:cell(name)="data">
                    {{data.item.name}}
                    <br>
                    <b-button class="actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_skillEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button class="actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathSkillDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                  <template v-slot:cell(image)="data">
                    <img :src="data.item.image" style="background-color: #e52e2c; width: 50px; height: 50px;">
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathSkillAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <GPathSkillAdd :propOpenedInModal="true" @emitCloseGPathSkillAddModal="closeGPathSkillAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_skillAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathSkillAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGPathSkillEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl"
    >
      <GPathSkillEdit :propOpenedInModal="true" :propGPathSkillObj="gpath_skillEditObj" @emitCloseGPathSkillEditModal="closeGPathSkillEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_skillEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathSkillEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGPathSkillDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathSkillDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_skillDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathSkills } from "../../../FackApi/api/GPathSkill"
import GPathSkillAdd from "./GPathSkillAdd"
import GPathSkillEdit from "./GPathSkillEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "GPathSkillList",
  components: {
    GPathSkillAdd,
    GPathSkillEdit
  },
  data () {
    return {
      apiName: "gpath_skills_list",
      cvCardTitle: "GPathS Skill",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPath Skill",
      cvAddModalHeader: "Add GPathSkill",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathSkill List Response",
      showModelGPathSkillAdd: false,
      showModelGPathSkillEdit: false,
      showModelGPathSkillDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "image", key: "image", class: "text-left align-text-top", sortable: true },
        { label: "name", key: "name", class: "text-left align-text-top", sortable: true }
      ],
      gpath_skillObjList: null,
      gpath_skillEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_skillList()
  },
  methods: {

    /**
     * gpath_skillList
     */
    async gpath_skillList () {
      try {
        let gpathSkillListResp = await GPathSkills.gpath_skillList(this)
        if (gpathSkillListResp.resp_status) {
          this.gpath_skillObjList = gpathSkillListResp.resp_data.data
          this.totalRows = gpathSkillListResp.resp_data.count
        }
        else {
          this.toastMsg = gpathSkillListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_skillList() and Exception:", err.message)
      }
    },
    /**
     * gpath_skillAdd
     */
    gpath_skillAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_skill_add")
        }
        else {
          this.showModelGPathSkillAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_skillAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_skillEdit
     */
    gpath_skillEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_skill_edit/" + this.gpath_skillEditObj.gps_id)
        }
        else {
          this.gpath_skillEditObj = item
          this.showModelGPathSkillEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_skillEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathSkillDeleteDialog
     */
    showGPathSkillDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathSkillDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathSkillDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_skillDelete
     */
    async gpath_skillDelete () {
      try {
        let gpathSkillDelResp = await GPathSkills.gpath_skillDelete(this, this.delObj.gps_id)
        await ApiResponse.responseMessageDisplay(this, gpathSkillDelResp)

        if (gpathSkillDelResp && !gpathSkillDelResp) {
          this.showModelGPathSkillDelete = false
          return false
        }

        let index = this.gpath_skillObjList.indexOf(this.delObj)
        this.gpath_skillObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathSkillDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_skillDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGPathSkillAddModal
     */
    closeGPathSkillAddModal (gpathSkillAddData) {
      try {
        if (gpathSkillAddData) {
          if (this.gpath_skillObjList && this.gpath_skillObjList.length >= 1) {
            let gpathSkillObjLength = this.gpath_skillObjList.length
            let lastId = this.gpath_skillObjList[gpathSkillObjLength - 1]["id"]
            gpathSkillAddData.id = lastId + 1
          }
          else {
            this.gpath_skillObjList = []
            gpathSkillAddData.id = 11111
          }

          gpathSkillAddData.created_on = moment()
          this.gpath_skillObjList.unshift(gpathSkillAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathSkillAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_skillAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGPathSkillEditModal
     */
    closeGPathSkillEditModal () {
      try {
        this.showModelGPathSkillEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathSkillEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
